import React, { Component } from 'react';
import {
    MDBCol,
    MDBContainer, MDBRow
} from 'mdbreact';
import downloadApp from "../Assets/downloadApp.svg";
import phones from "../Assets/phones.png";
import apple from "../Assets/apple-store-badge.svg";
import google from "../Assets/google-play-badge.svg";
import './DownloadApp.scss';

class DownloadApp extends Component {

    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <div className='download-app'>
                <MDBContainer>
                    <MDBRow className="d-md-flex d-none">
                        <MDBCol md='5'>
                            <MDBRow className='pb-4'>
                                <small className='brown-color-used'>MONTY CLUB APP</small>
                            </MDBRow>
                            <MDBRow className='p-0'>
                                <h2 className='bigger-title-size'>
                                    Membership
                                </h2>
                            </MDBRow>
                            <MDBRow className='py-2'>
                                <p className='bigger-text-size'>
                                    Download & register to become a Member.<br/><br/>
                                    The membership at Monty Club is made up of hand-picked like-minded people.
                                    <br/><br/>
                                    Applicants will be notified of their application’s status within 7 working days from submission.
                                </p>
                            </MDBRow>
                            <MDBRow className='mt-3 d-flex'>
                                <MDBCol md="12">
                                    <a href="https://play.google.com/store/apps/details?id=com.montymobile.montyclub" rel="noreferrer" target="_blank" className='mr-2'><img src={google} alt="google" className='store-button'/></a>
                                    <a href="https://apps.apple.com/us/app/id1610742253" rel="noreferrer" target="_blank"><img src={apple} alt="apple" className='store-button'/></a>
                                </MDBCol>
                                {/* <MDBCol md="12" className='ml-1 mt-3'>
                                    {/* <img src={downloadApp} alt="Download App" className='download-image' /> */}
                                    {/*<img src={apple} alt="apple" />
                                </MDBCol> */}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol md='7'>
                            
                                <img src={phones} alt="Phones" className="phones-img" />
                            
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="d-block d-md-none">
                        <MDBCol md='6'>
                            <MDBRow className='pb-4'>
                                <small className='brown-color-used font-weight-bold'>MONTY CLUB APP</small>
                            </MDBRow>
                            <MDBRow className='p-0'>
                                <h2 className='font-weight-bold bigger-title-size'>
                                    Membership
                                </h2>
                            </MDBRow>
                            <MDBRow className='py-4'>
                                <p>
                                    Download & register to become a Member.<br/><br/>
                                    The membership at Monty Club is made up of hand-picked like-minded people.
                                    <br/><br/>
                                    Applicants will be notified of their application’s status within 7 working days from submission.
                                </p>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="12" className='pl-0'>
                                    <img src={phones} alt="Phones" className="phones-img" style={{ transform: "scale(0.8)" }} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol md='6'>
                            <MDBRow>
                                <img src={downloadApp} alt="Download App" className='download-image' />
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }
}

export default DownloadApp