
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../Containers/HomePage";
import TopNav from "../Containers/TopNav";
import {
    MDBContainer, MDBRow, MDBCol
} from 'mdbreact';


// import facebook from '../Assets/social/facebook.svg';
// import instagram from '../Assets/social/instagram.svg';
// import linkedin from '../Assets/social/linkedin.svg';
// import tiktok from '../Assets/social/tiktok.svg';
// import twitter from '../Assets/social/twitter.svg';

import facebook from '../Assets/social/facebook-grey.svg';
import instagram from '../Assets/social/instagram-grey.svg';
import TermsConditions from "../Containers/TermsConditions";
import FAQ from "../Containers/FAQ";


class Routes extends Component {
    constructor() {
        super();
        this.state = {
            openTermsConditions: false,
            openFAQ: false
        }
    }

    toggleTermsConditions = () => {
        this.setState({
            openTermsConditions: !this.state.openTermsConditions
        })
    }

    toggleFAQ = () => {
        this.setState({
            openFAQ: !this.state.openFAQ
        })
    }

    render() {
        return (
            <>
                <MDBContainer>
                    <MDBRow className="upper-top">
                        <MDBCol md="6" align="left" className="f7-color">
                            <ul className="p-0 d-block d-md-none">
                                <li className="d-inline-block p-3" onClick={this.toggleTermsConditions}> Terms &amp; Conditions</li>
                                <li className="d-inline-block" onClick={this.toggleFAQ}> FAQ </li>
                            </ul>
                            <ul className="d-none d-md-block">
                                <li className="d-inline-block mr-5 p-3" onClick={this.toggleTermsConditions}> Terms &amp; Conditions</li>
                                <li className="d-inline-block" onClick={this.toggleFAQ}> FAQ </li>
                            </ul>
                        </MDBCol>
                        <MDBCol md="6" className="d-none d-md-block">
                            {/* <ul>
                                <li className="d-inline-block pt-3"><MDBIcon fab icon="facebook-f" className="grey-bg" /></li>
                                <li className="d-inline-block"><MDBIcon fab icon="linkedin-in" className="grey-bg" /></li>
                                <li className="d-inline-block"><MDBIcon fab icon="instagram" className="grey-bg" /></li>
                                <li className="d-inline-block"><MDBIcon fab icon="twitter" className="grey-bg" /></li>
                            </ul> */}
                            <MDBRow>
                                <MDBCol md="12" className="text-right">
                                    <img src={facebook} alt="facebook" className='mt-3 mr-3 pt-2 hover' width="17px" onClick={()=>{window.open("https://www.facebook.com/MontyPrivateClub/");}}/>
                                    {/* <img src={tiktok} alt="tiktok" className='mt-3 mr-3 pt-2 hover' width="17px" />
                                    <img src={linkedin} alt="linkedin" className='mt-3 mr-3 pt-2 hover' width="17px" /> */}
                                    <img src={instagram} alt="instagram" className='mt-3 mr-3 pt-2 hover' width="17px" onClick={()=>{window.open("https://www.instagram.com/monty_privateclub/");}}/>
                                    {/* <img src={twitter} alt="twitter" className='mt-3 mr-3 pt-2 hover' width="17px" /> */}
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <TopNav />
                <Switch>
                    <Route path={"/"} exact component={HomePage} />
                </Switch>
                <TermsConditions openTermsConditions={this.state.openTermsConditions} toggleTermsConditions={this.toggleTermsConditions}/>
                <FAQ openFAQ={this.state.openFAQ} toggleFAQ={this.toggleFAQ}/>
            </>
        )
    }
}
export default Routes