import React, { Component } from 'react';
import { Element } from 'react-scroll'
import backtotop from "../Assets/BackToTop.svg";
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import DiscoverRule from './DiscoverRule';
import DownloadApp from './DownloadApp';
import Footer from './Footer';
import OurGallery from './OurGallery';
import OurMenu from './OurMenu';
import Service from './Service';
import "./WelcomePage.scss";


class HomePage extends Component {

  constructor() {
    super();
    this.state = {

    }
  }

  backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      spy: "true"
    });
  }

  render() {
    return (
      <>
        <div className='welcome-page'>
          <h1>
            Welcome to Monty Club
          </h1>
          <p className='mb-0'> We serve the Elite</p>
          {/* <div className='play-btn'>
            <img src={play} alt="Play btn" />
            <p>
              WATCH VIDEO
            </p>
          </div> */}
        </div>

        <Element name="aboutus" className="about-us">
          <AboutUs />
        </Element>

        <Element name="rule" className="discover-rule">
          <DiscoverRule />
        </Element>

        {/* <Element name="members" className="black-tie">
          <Video />
        </Element> */}

        <Element name="menu" className="our-menu">
          <OurMenu />
        </Element>

        <Element name="members" className="service">
          <Service />
        </Element>

        {/* <Element name="members" className="become-member">
          <BecomeAMember />
        </Element> */}

        <Element name="media" className="our-gallery">
          <OurGallery />
        </Element>

        <Element name="members" className="download-app">
          <DownloadApp />
        </Element>

        <Element name="contactus" className="contactus">
          <ContactUs />
        </Element>

        <Element name="footer" className="footer">
          <Footer />
        </Element>
        <div className="backtotop-btn">
          {/* <Link className="logo home" to="home" spy={true} smooth={true} duration={500}> */}
            <img src={backtotop} alt="Back To Top btn" width="56" height="56" onClick={this.backToTop}/>
          {/* </Link> */}
        </div>
      </>
    )
  }

}

export default HomePage