import React, { Component } from 'react';
// import privateIcon from '../Assets/private-room.svg'
import './Service.scss';
import {
    MDBCol,
    MDBContainer, MDBRow
} from 'mdbreact';

class Service extends Component {

    constructor() {
        super();
        this.state = {

        }
    }

    render() {
        return (
            <div className='service'>
                <MDBContainer className='service-container desktop-padding'>
                    <MDBRow>
                        <MDBCol md="12">
                            <small className='brown-color-used more-letter-spacing'>At your service</small>
                            <h2 className='bigger-title-size pt-4'>
                                Serving the Elite
                            </h2>
                            <p className='middle'>At its core, The Club remains a place to entertain and be entertained. With a timeless combination of comfort, glamour and intimacy, The Club continually strives to create an atmosphere that is unforgettable and celebratory.</p>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md="12" className='mt-4'>
                            <hr className='divider' />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md="12" vl>
                            <h2 className='smaller-title-size pt-4'>
                                <p >At Monty Club</p>
                                <span>We Serve the Elite.</span>
                            </h2>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }

}

export default Service;