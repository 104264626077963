import React, { Component } from 'react';
import {
    MDBCol,
    MDBContainer, MDBIcon, MDBModal, MDBModalBody, MDBRow
} from 'mdbreact';
import './OurMenus.scss'
import menuImage from '../Assets/menu-image.png'
import dish1 from '../Assets/minizette.jpg'
import dish2 from '../Assets/ceasar.jpg'
import dish3 from '../Assets/mushroom.jpg'
import dish4 from '../Assets/chocolate.jpg'
import dish5 from '../Assets/salmon.jpg'
import dish6 from '../Assets/slider.jpg'
import dish7 from '../Assets/goat.jpg'
import dish8 from '../Assets/ribeye.jpg'

//desktop
import dish1Desktop from '../Assets/menu-desktop/minizette-desktop.jpg'
import dish2Desktop from '../Assets/menu-desktop/ceasar-desktop.jpg'
import dish3Desktop from '../Assets/menu-desktop/mushroom-desktop.jpg'
import dish4Desktop from '../Assets/menu-desktop/chocolate-desktop.jpg'
import dish5Desktop from '../Assets/menu-desktop/salmon-desktop.jpg'
import dish6Desktop from '../Assets/menu-desktop/slider-desktop.jpg'
import dish7Desktop from '../Assets/menu-desktop/goat-desktop.jpg'
import dish8Desktop from '../Assets/menu-desktop/ribeye-desktop.jpg'

//mobile
import dish1Mobile from '../Assets/menu-mobile/minizette-mobile.jpg'
import dish2Mobile from '../Assets/menu-mobile/ceasar-mobile.jpg'
import dish3Mobile from '../Assets/menu-mobile/mushroom-mobile.jpg'
import dish4Mobile from '../Assets/menu-mobile/chocolate-mobile.jpg'
import dish5Mobile from '../Assets/menu-mobile/salmon-mobile.jpg'
import dish6Mobile from '../Assets/menu-mobile/slider-mobile.jpg'
import dish7Mobile from '../Assets/menu-mobile/goat-mobile.jpg'
import dish8Mobile from '../Assets/menu-mobile/ribeye-mobile.jpg'

import fullMenu from "../Assets/fullMenu.png";

class OurMenu extends Component {

    constructor() {
        super();
        this.state = {
            menuModal: false,
            dish: 1,
            dishSrc: dish1Desktop
        }
    }

    toggleMenuModal = () => {
        this.setState({
            menuModal: !this.state.menuModal,
        })
    }

    toggleOpenMenuModal = (dish) => {
        switch (dish) {
            case 1:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish1Desktop
                })
                break;
            case 2:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish2Desktop
                })
                break;
            case 3:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish3Desktop
                })
                break;
            case 4:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish4Desktop
                })
                break;
            case 5:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish5Desktop
                })
                break;
            case 6:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish6Desktop
                })
                break;
            case 7:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish7Desktop
                })
                break;
            case 8:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish8Desktop
                })
                break;
            default:
                this.setState({
                    menuModal: true,
                    dish: 1,
                    dishSrc: dish1Desktop
                })
        }
    }

    toggleOpenMobileMenuModal = (dish) => {
        switch (dish) {
            case 1:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish1Mobile
                })
                break;
            case 2:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish2Mobile
                })
                break;
            case 3:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish3Mobile
                })
                break;
            case 4:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish4Mobile
                })
                break;
            case 5:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish5Mobile
                })
                break;
            case 6:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish6Mobile
                })
                break;
            case 7:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish7Mobile
                })
                break;
            case 8:
                this.setState({
                    menuModal: true,
                    dish,
                    dishSrc: dish8Mobile
                })
                break;
            default:
                this.setState({
                    menuModal: true,
                    dish: 1,
                    dishSrc: dish1Mobile
                })
        }
    }

    toggleCloseMenuModal = () => {
        this.setState({
            menuModal: false
        })
    }

    render() {
        return (
            <div className='menu'>
                <MDBContainer className='py-5 desktop-padding'>
                    <MDBRow>
                        <MDBCol md="6" className='left-menu-col-image'>
                            <MDBRow center>
                                <img src={menuImage} alt="menu-pic" className='menu-image-style'/>
                            </MDBRow>
                        </MDBCol>
                       
                        <MDBCol md="6" className='mt-4 right-menu-col'>
                            <MDBContainer>
                                <MDBRow className='my-3'>
                                    <small className='p-0 brown-color-used'>OUR MENUS</small> 
                                </MDBRow>
                                <MDBRow className='my-1'>
                                    <h2 className='menus-title'>
                                        Special Menus
                                    </h2>
                                </MDBRow>
                                <MDBRow className='menus-description'>
                                    <p className='p-0'>An outstanding menu, cocktail and wine lists have been designed for The Club’s Members and their guests to choose from. Expertly sourced, seasonal ingredients, wines and spirits are brought together using both traditional and innovative techniques to create an exceptional dining experience.</p>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='my-5 desktop-view'>
                        <MDBCol md="6" className='left-menu-col'>
                            <MDBContainer>
                                <MDBRow>
                                    <small className='brown-color-used menu-type'>POPULAR DISHES</small> 
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <span className="col-md-10 cursor-pointer p-0" onClick={() => this.toggleOpenMenuModal(1)}>
                                        <div className="d-flex">
                                            <div className='dishName-container'>
                                                <span className='dish-name'>Minizette</span>
                                            </div>
                                            <span className='border-added'/>
                                        </div>
                                        <div>
                                            <small className='dish-ingredients'>Freshly baked crust topped with our homemade pesto sauce... </small>
                                        </div>
                                    </span>
                                    <span className="col-md-2 cursor-pointer" onClick={() => this.toggleOpenMenuModal(1)}>
                                        <img src={dish1} alt="dish" className='dish-image'/>
                                    </span>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <span className="col-md-10 cursor-pointer p-0" onClick={() => this.toggleOpenMenuModal(2)}>
                                        <div className="d-flex">
                                            <div className='dishName-container'>
                                                <span className='dish-name'>Caesar Salad</span>
                                            </div>
                                            <span className='border-added'/>
                                        </div>
                                        <div>
                                            <small className='dish-ingredients'>Romaine lettuce dressed with our homemade Caesar sauce... </small>
                                        </div>
                                    </span>
                                    <span className="col-md-2 cursor-pointer" onClick={() => this.toggleOpenMenuModal(2)}>
                                        <img src={dish2} alt="dish" className='dish-image'/>
                                    </span>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <span className="col-md-10 cursor-pointer p-0" onClick={() => this.toggleOpenMenuModal(3)}>
                                        <div className="d-flex">
                                            <div className='dishName-container'>
                                                <span className='dish-name'>Mushroom Grilled Chicken</span>
                                            </div>
                                            <span className='border-added'/>
                                        </div>
                                        <div>
                                            <small className='dish-ingredients'>Grilled chicken breast topped with our special mushroom sauce... </small>
                                        </div>
                                    </span>
                                    <span className="col-md-2 cursor-pointer" onClick={() => this.toggleOpenMenuModal(3)}>
                                        <img src={dish3} alt="dish" className='dish-image'/>
                                    </span>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <span className="col-md-10 cursor-pointer p-0" onClick={() => this.toggleOpenMenuModal(4)}>
                                        <div className="d-flex">
                                            <div className='dishName-container'>
                                                <span className='dish-name'>Double chocolate fondant </span>
                                            </div>
                                            <span className='border-added'/>
                                        </div>
                                        <div>
                                            <small className='dish-ingredients'>A mouthwatering fusion of melted dark and white chocolate... </small>
                                        </div>
                                    </span>
                                    <span className="col-md-2 cursor-pointer" onClick={() => this.toggleOpenMenuModal(4)}>
                                        <img src={dish4} alt="dish" className='dish-image'/>
                                    </span>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                      
                        <MDBCol md="6" className='right-menu-col'>
                            <MDBContainer>
                                <MDBRow>
                                    <small className='brown-color-used font-weight-bold menu-type'>CHEF RECOMMENDS</small> 
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <span className="col-md-10 cursor-pointer p-0" onClick={() => this.toggleOpenMenuModal(5)}>
                                        <div className="d-flex">
                                            <div className='dishName-container'>
                                                <span className='dish-name'>Salmon Blinis</span>
                                            </div>
                                            <span className='border-added'/>
                                        </div>
                                        <div>
                                            <small className='dish-ingredients'>Homemade blinis, topped with our special cream cheese mix... </small>
                                        </div>
                                    </span>
                                    <span className="col-md-2 cursor-pointer" onClick={() => this.toggleOpenMenuModal(5)}>
                                        <img src={dish5} alt="dish" className='dish-image'/>
                                    </span>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <span className="col-md-10 cursor-pointer p-0" onClick={() => this.toggleOpenMenuModal(6)}>
                                        <div className="d-flex">
                                            <div className='dishName-container'>
                                                <span className='dish-name'>Monty Club Sliders</span>
                                            </div>
                                            <span className='border-added'/>
                                        </div>
                                        <div>
                                            <small className='dish-ingredients'>Two juicy beef sliders, made from premium quality meat, grilled... </small>
                                        </div>
                                    </span>
                                    <span className="col-md-2 cursor-pointer" onClick={() => this.toggleOpenMenuModal(6)}>
                                        <img src={dish6} alt="dish" className='dish-image'/>
                                    </span>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <span className="col-md-10 cursor-pointer p-0" onClick={() => this.toggleOpenMenuModal(7)}>
                                        <div className="d-flex">
                                            <div className='dishName-container'>
                                                <span className='dish-name'>Goat Cheese Kale Salad </span>
                                            </div>
                                            <span className='border-added'/>
                                        </div>
                                        <div>
                                            <small className='dish-ingredients'>Fresh green kale, dried fruits, almonds, mixed with a homemade... </small>
                                        </div>
                                    </span>
                                    <span className="col-md-2 cursor-pointer" onClick={() => this.toggleOpenMenuModal(7)}>
                                        <img src={dish7} alt="dish" className='dish-image'/>
                                    </span>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <span className="col-md-10 cursor-pointer p-0" onClick={() => this.toggleOpenMenuModal(8)}>
                                        <div className="d-flex">
                                            <div className='dishName-container'>
                                                <span className='dish-name'>Ribeye Steak (250 grs) </span>
                                            </div>
                                            <span className='border-added'/>
                                        </div>
                                        <div>
                                            <small className='dish-ingredients'>Ribeye Angus Beef seasoned to perfection. Served with our...</small>
                                        </div>
                                    </span>
                                    <span className="col-md-2 cursor-pointer" onClick={() => this.toggleOpenMenuModal(8)}>
                                        <img src={dish8} alt="dish" className='dish-image'/>
                                    </span>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='my-5 mobile-view'>
                        <MDBCol md="6" className='left-menu-col'>
                            <MDBContainer>
                                <MDBRow>
                                    <small className='brown-color-used menu-type'>POPULAR DISHES</small> 
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <MDBCol size="10" className="pl-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(1)}>
                                        <span className='dish-name'>Minizette</span>
                                        <small className='dish-ingredients'>Freshly baked crust topped with our homemade pesto sauce... </small>
                                    </MDBCol>
                                    <MDBCol size="2" className="pr-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(1)}>
                                        <img src={dish1} alt="dish" className='dish-image'/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <MDBCol size="10" className="pl-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(2)}>
                                        <span className='dish-name'>Caesar Salad</span>
                                        <small className='dish-ingredients'>Romaine lettuce dressed with our homemade Caesar sauce... </small>
                                    </MDBCol>
                                    <MDBCol size="2" className="pr-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(2)}>
                                        <img src={dish2} alt="dish" className='dish-image'/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <MDBCol size="10" className="pl-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(3)}>
                                        <span className='dish-name'>Mushroom Grilled Chicken</span>
                                        <small className='dish-ingredients'>Grilled chicken breast topped with our special mushroom sauce... </small>
                                    </MDBCol>
                                    <MDBCol size="2" className="pr-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(3)}>
                                        <img src={dish3} alt="dish" className='dish-image'/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <MDBCol size="10" className="pl-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(4)}>
                                        <span className='dish-name'>Double chocolate fondant </span>
                                        <small className='dish-ingredients'>A mouthwatering fusion of melted dark and white chocolate... </small>
                                    </MDBCol>
                                    <MDBCol size="2" className="pr-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(4)}>
                                        <img src={dish4} alt="dish" className='dish-image'/>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                      
                        <MDBCol md="6" className='right-menu-col'>
                            <MDBContainer>
                                <MDBRow>
                                    <small className='brown-color-used menu-type'>CHEF RECOMMENDS</small> 
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <MDBCol size="10" className="pl-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(5)}>
                                        <span className='dish-name'>Salmon Blinis</span>
                                        <small className='dish-ingredients'>Homemade blinis, topped with our special cream cheese mix... </small>
                                    </MDBCol>
                                    <MDBCol size="2" className="pr-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(5)}>
                                        <img src={dish5} alt="dish" className='dish-image'/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <MDBCol size="10" className="pl-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(6)}>
                                        <span className='dish-name'>Monty Club Sliders</span>
                                        <small className='dish-ingredients'>Two juicy beef sliders, made from premium quality meat, grilled... </small>
                                    </MDBCol>
                                    <MDBCol size="2" className="pr-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(6)}>
                                        <img src={dish6} alt="dish" className='dish-image'/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <MDBCol size="10" className="pl-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(7)}>
                                        <span className='dish-name'>Goat Cheese Kale Salad </span>
                                        <small className='dish-ingredients'>Fresh green kale, dried fruits, almonds, mixed with a homemade... </small>
                                    </MDBCol>
                                    <MDBCol size="2" className="pr-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(7)}>
                                        <img src={dish7} alt="dish" className='dish-image'/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='py-4'>
                                    <MDBCol size="10" className="pl-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(8)}>
                                        <span className='dish-name'>Ribeye Steak (250 grs) </span>
                                        <small className='dish-ingredients'>Ribeye Angus Beef seasoned to perfection. Served with our...</small>
                                    </MDBCol>
                                    <MDBCol size="2" className="pr-0 cursor-pointer" onClick={() => this.toggleOpenMobileMenuModal(8)}>
                                        <img src={dish8} alt="dish" className='dish-image'/>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='my-2 mt-5' center>
                        <MDBCol md="12" className='d-flex justify-content-center'>
                            <a href="https://montyclub.co.uk/Menu/MontyClub-Menu.pdf" target="_blank" rel="noreferrer">
                                {/* <button className='full-menu-button'>
                                    FULL MENU
                                </button> */}
                                <img src={fullMenu} alt={"Full Menu"} width={"140px"} height={"50px"} className="hover"/>
                            </a>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                {<MDBModal isOpen={this.state.menuModal} toggle={this.toggleMenuModal} centered size='lg' className='transparent-model'>
                    <button className="menu-times-btn" onClick={this.toggleCloseMenuModal}>
                        <MDBIcon icon="times"/>
                    </button>
                    <MDBModalBody className='p-0'>
                        <img src={this.state.dishSrc} alt="Dish" width="100%"/>
                    </MDBModalBody>
                </MDBModal>}
            </div>
        )
    }

}

export default OurMenu