import { MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow } from 'mdbreact'
import React, { Component } from 'react'

class TermsConditions extends Component {
    constructor() {
        super();
        this.state = {}
    }

    render() {
        return (
            <MDBModal
                size="xl"
                centered
                isOpen={this.props.openTermsConditions}
                toggle={this.props.toggleTermsConditions}
                className="h-auto custom-blurry-modal modal-fluid"
            >
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="12" className='d-flex justify-content-end moreMarginTop'>
                            <button className='x-times' onClick={this.props.toggleTermsConditions}>
                                <MDBIcon icon="times"/>
                            </button>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol xl="3" lg="3">
                           <div className='margin-on-bigger-screen'>
                           <small className='brown-color-used font-weight-bold'>DISCOVER our Terms of service</small>
                           </div>
                           
                            <h2 className='font-weight-bold bigger-title-size pt-3 margin-on-bigger-screen'>
                                Terms & Conditions
                            </h2>
                        </MDBCol>
                        <MDBCol xl="9" lg="9" className='scrollable mb-5'>

                            <div><h5>1.	GUIDELINES</h5></div>
                            <div>1.1. Monty Club, Private Business Lounge (hereinafter referred to as “Monty Club” and/or “Club”) Members (hereinafter referred to as “Members”) have the right to use all common areas of the Club. Members are recommended to book their tables prior to visiting the Club, otherwise seating will be subject to availability.
                            </div>
                            <br />
                            <div>
                            1.2.	The consumption of food, drinks and alcoholic beverages are subject to charge. For other services such as but not limited to private events, meeting rooms etc., Monty Club management may ask for advance payment to secure bookings.
                            </div>
                            <br />
                            <div>
                            1.3	Members must strictly adhere to rules of the Club (see sections 12 and 8 respectively).
                            </div>
                            <br />
                            <div>
                            1.4	Members undertake not to use the Premises as their registered business address.
                            </div>
                            <br />
                            <div>
                            1.5	Whilst on the Premises, Members shall always act in a manner that is respectful and courteous towards other Members, Guests, Club staff members, and all other third parties (see section 11).
                            </div>
                            <br />
                            <div>
                            1.6	Consumption of outside food and drinks is not allowed on the Premises. 
                            </div>
                            <br />
                            <div>
                            1.7	For privacy reasons, photo shooting at the Club is strictly forbidden. Refusing to respect this rule may lead to Membership suspension at the discretion of Monty Club
                            </div>
                            <br />
                            <div>
                            1.8	The Club reserves the right to alter or terminate Membership, or any account there under, at any time, in its sole and absolute discretion for any reason with or without notice. Under these circumstances, the Club’s liability shall be limited exclusively to fees paid by the Member in advance for that 12-month Membership period.
                            </div>
                            <br />
                            <div>
                            1.9	The Club may terminate a Membership immediately if a Member materially and/or repeatedly breaches the Terms and Conditions.
                            </div>
                            <br />
                            <div>
                            1.10. The Club reserves the right to unilaterally amend the Terms and Conditions from time to time with or without notice. All changes will take immediate effect. Members will be notified on the MONTY CLUB Application (hereinafter referred to as “App”) about any changes. Members will be deemed to have accepted any such changes by their continued use of the Club.
                            </div>
                            <br />
                            <div><h5>2.	ADMISSION</h5></div>
                            <br />
                            <div>
                            2.1	Members and Guests will be admitted into the Club during the following normal opening hours: Monday to Sunday 10:00am – 12:00am. Any changes in opening schedule will be notified on the App. 
                            </div>
                            <br />
                            <div>
                            2.2	Members will be notified when the club is closed for special occasions or private events.
                            </div>
                            <br />
                            <div><h5>3.	GENERAL TERMS AND CONDITIONS</h5></div>
                            <br />
                            <div>
                            3.1.	Each Member shall abide by the Terms and Conditions and all related by-laws, policies, regulations, or other directions required by the Club, as may be updated , and be notified from time to time.
                            </div>
                            <br />
                            <div>
                            3.2.	The Terms and Conditions automatically apply to Member’s use of any service at the Club Premises.
                            </div>
                            <br />
                            <div>
                            3.3.	The Club Services may be used by adults only aged 21 and over.
                            </div>
                            <br />
                            <div>
                            3.4.	Smoking is allowed only in specific areas. 
                            </div>
                            <br />
                            <div>
                            3.5.	Club Membership identification must always be carried by Members when visiting the Club. 
                            </div>
                            <br />
                            <div>
                            3.6.	Pets are not allowed on the Premises under any circumstances.
                            </div>
                            <br />
                            <div>
                            3.7.	The Club shall not be liable whatsoever for any loss or damage suffered by a Member, Guest or other party at the Club Premises. Such damage or loss includes, but is not limited to, any loss of property, profit, business, business interruption, or loss of business opportunity.
                            </div>
                            <br />
                            <div>
                            3.8.	Membership constitutes an agreement between the Member and the Club which is governed by these Terms and Conditions. No other third party shall have any rights to enforce these Terms and Conditions.
                            </div>
                            <br />
                            <div><h5>4.	MEMBERSHIP AND FEES</h5></div>
                            <br />
                            <div>
                            4.1.	A Joining fee is a one-time charge to be paid in cash upon membership approval. The joining fee is for USD//1,000.00// (One Thousand United States Dollars) paid only in cash. 
                            </div>
                            <br />
                            <div>
                            4.2.	Elite Memberships consist of a twelve-month minimum commitment, renewed annually.
                            </div>
                            <br />
                            <div>
                            4.3.	Elite Membership is for USD//10,000.00// (Ten Thousand United States Dollars) per year, to be paid only in cash.
                            </div>
                            <br />
                            <div>
                            4.4.	Membership requests are to be approved exclusively by the Club’s management in its sole and absolute discretion and are non- transferrable. 
                            </div>
                            <br />
                            <div>
                            4.5.	The Club may from time to time add additional Membership types or amend the benefits attached to each type of Membership with notice.
                            </div>
                            <br />
                            <div>
                            4.6.	Each Member shall ensure the Club is informed of their correct and up-to-date address for correspondence and full contact details including telephone and e-mail address, together with emergency contact details.
                            </div>
                            <br />
                            <div>
                            4.7.	Elite Membership Fees, payable by a Club Member, is non-refundable under any circumstances.
                            </div>
                            <br />
                            <div>
                            4.8.	Membership is active only after receiving full membership payment by Monty Club. Renewal of a membership will be automatically deactivated If a Club member fails to pay the renewal fees within 30 (thirty) days from the due date.
                            </div>
                            <br />
                            <div>
                            4.9.	If a Club Member, having paid their Membership Fees in advance for the year, terminates their Membership before the expiry of that year, the Member shall not be entitled whatsoever to any pro-rata refund from Monty Club.
                            </div>
                            <br />
                            <div>
                            4.10.	Membership Fees are subject to change at the sole and absolute discretion of Monty Club. Paid memberships will not be subject to any such changes before the end of their 12-month Membership period.
                            </div>
                            <br />
                            <div>
                            4.11.	The Club reserves the right not to renew any Membership in its sole and absolute discretion, without any liability, and is under no obligation to provide a reason for their decision.
                            </div>
                            <br />
                            <div><h5>5.	APPLICATION PROCEDURE</h5></div>
                            <br />
                            <div>
                            5.1.	All Candidates must be aged 21 years or over at the time of their application.
                            </div>
                            <br />
                            <div>
                            5.2.	Applicants must be recommended by at least one Club Active member, or by the Club’s Management Committee. 
                            </div>
                            <br />
                            <div>
                            5.3.	Monty Club management may contact the proposer to verify the Candidate’s details and identity.
                            </div>
                            <br />
                            <div>
                            5.4.	The application must be completed in full, after which time all applications will be considered by the Club Committee for admission to the Club at a predetermined date.
                            </div>
                            <br />
                            <div>
                            5.5.	Outcomes for all applications will be notified to Candidates within 7 (seven) working days after form submission.
                            </div>
                            <br />
                            <div>
                            5.6.	The Club’s Committee’s decision to accept, reject, or return the application to the waiting list is final and no reason need to be given. Monty Club will not be held liable whatsoever for any decision made.
                            </div>
                            <br />
                            <div><h5>6.	RESIGNATION & TERMINATION</h5></div>
                            <br />
                            <div>
                            6.1.	Any Club Member wishing to resign must do so in writing, including via email, to Monty Club Membership team to the following e-mail address: info@montyclub.co.uk. Resigning members will be notified of the termination of the membership via email, within 7 (seven) days of receiving the resignation request. 
                            </div>
                            <br />
                            <div>
                            6.2.	Where Membership is terminated by way of Member’s resignation, previously paid Membership Fees will not be refunded by Monty Club to the Member unless agreed otherwise by Monty Club Management in writing. 
                            </div>
                            <br />
                            <div>
                            6.3.	Temporary suspension of Memberships is not available.
                            </div>
                            <br />
                            <div>
                            6.4.	Any Joining Fee paid is non-refundable by Monty Club whatsoever.
                            </div>
                            <br />
                            <div>
                            6.5.	Monty Club management may terminate a Membership in its sole and absolute discretion if that Member materially and/or repeatedly breaches the Terms and Conditions and with no liability on Monty Club.
                            </div>
                            <br />
                            <div>
                            6.6.	The end of any Membership, for any reason whatsoever, shall not affect Monty Club’s accrued rights or remedies at law or in equity.
                            </div>
                            <br />
                            <div><h5>7.	MEMBERSHIP CARD</h5></div>
                            <br />
                            <div>
                            7.1.	Club Members are solely responsible for their Membership card and must always keep their Membership card with them when visiting Monty Club. 
                            </div>
                            <br />
                            <div>
                            7.2.	The Membership card issued to members shall always remain the sole property of the Club and must be surrendered immediately upon request made by Monty Club.
                            </div>
                            <br />
                            <div>
                            7.3.	A Membership card may only be used by the Club Member it is issued to. Allowing another person to use one’s Club Membership card may result in immediate termination of Club Membership.
                            </div>
                            <br />
                            <div>
                            7.4.	Any lost or stolen Club Membership cards must be immediately reported to the Club.
                            </div>
                            <br />
                            <div>
                            7.5.	A replacement membership card will be issued at a cost of USD //40// (Forty United States Dollars) per card.
                            </div><br />
                            <div>
                            7.6.	The Club membership card serves its validity only.  In case Club membership is not renewed before expiry, the Club membership will be deactivated.
                            </div>
                            <br />
                            <div><h5>8.	GUESTS</h5></div>
                            <br />
                            <div>
                            8.1.	Club Members are allowed to bring Guests into the Club’s communal areas at any given time. 
                            </div>
                            <br />
                            <div>
                            8.2.	Club Reception must be informed of all Guests’ names, and Club Members must sign Guests in.
                            </div>
                            <br />
                            <div>
                            8.3.	Guests must be accompanied by at least one Club member to access Monty Club. 
                            </div>
                            <br />
                            <div>
                            8.4.	Club Members shall ensure that Guests abide by these Terms and Conditions and exercise such courtesy as is expected from Club Members when attending the Club. Club Members are responsible for the behavior of, and will be financially liable for, their Guests and for all Services used by them at the Club.
                            </div>
                            <br />
                            <div>
                            8.5.	Guests may not remain in the Club once the sponsoring Club Member has left the Club Premises.
                            </div>
                            <br />
                            <div>
                                <h5>9.	BILLS</h5>
                            </div>
                            <br />
                            <div>
                            9.1.	Club Members and accompanied guests must settle their bills before leaving the Club Premises. In the case of unpaid accounts or disputes, the outstanding amount will be the responsibility of the Club Member who signed the Guest(s) into the Club.
                            </div>
                            <br />
                            <div>
                            9.2.	Monty Club Management will not extend any credit lines to any Club Member. 
                            </div>
                            <br />
                            <div>
                            9.3.	Gratuities/tips are not included in the price of any Services and/or additional services offered by the Club.
                            </div>
                            <br />
                            <div>
                            9.4.	The Club reserves the right to refuse entry to any Club Member with any outstanding amount owed to Monty Club, notwithstanding any other right and/or remedy available at law or in equity to Monty Club.
                            </div>
                            <br />
                            <div>
                            9.5.	Any Club Member with unpaid balance may have their Membership suspended until the outstanding balance is cleared upon the sole and absolute decision of Monty Club.
                            </div>
                            <br />
                            <div>
                                <h5>10.	MEMBER CONDUCT	</h5>
                            </div>
                            <br />
                            <div>
                            10.1.	Whilst on the Club Premises, Club Members shall always act in a manner that is respectful and courteous towards other Club Members, Guests, Club staff members, and all other third parties. Club Members shall not act in a manner that is in any-way abusive, harmful, or offensive to such persons.
                            </div>
                            <br />

                            <div>
                            10.2.	The Club may, at any time, and its sole and absolute discretion, refuse to serve alcohol to a Club member that has received a warning for their conduct.
                            </div>
                            <br />
                            <div>
                            10.3.	Whilst using the Club Services, Club Members should be mindful of the wishes and needs of others to do the same and should not abuse their rights to use the Club Services.
                            </div>
                            <br />
                            <div>
                            10.4.	Conduct that is prejudicial to the reputation or good-running of the Club in the sole and absolute opinion of Monty Club, may result in expulsion, notwithstanding any other right and/or remedy available at law or in equity to Monty Club. Such conduct includes but is not limited to violent or abusive behavior.
                            </div>
                            <br />
                            <div>
                            10.5.	Club Members and their Guests shall not, at any time whatsoever, disclose to any person any information, whether confidential or otherwise, they have seen or heard whilst on the Club Premises, including, but not limited to, general usage of the Club, the Club Premises, other Club Members or Guests.
                            </div>
                            <br />
                            <div>
                            10.6.	The Club may evict or exclude any Club Member or Guest whose behavior it considers breaching the Terms and Conditions, at its sole and absolute discretion and with no liability on Monty Club. Any failure by that Club Member or Guest to leave the Club Premises pursuant to this term may result in the suspension or termination of the Club Membership of the Member, or if the latter, the Club Member responsible for the Guest, contravening this term.
                            </div>
                            <br />
                            <div><h5>11.	FAIR USAGE POLICY</h5></div>
                            <br />
                            <div>
                            11.1.	Occupancy of the Club is likely to fluctuate from time to time. At peak times, seating areas should be shared where possible.
                            </div>
                            <br />
                            <div>
                            11.2.	Club Members are welcome to come and go as many times as required during the daily opening hours.
                            </div>
                            <br />
                            <div>
                            11.3.	Under no circumstances should personal items (laptops, notebooks or other) be left unattended in any of the communal areas when Club Members and guests leave the Club Premises or are not occupying that space for 30 (thirty) minutes or more.
                            </div>
                            <br />
                            <div>
                            11.4.	Any unattended personal items will be removed from all communal areas and placed in the cloakroom at Club’s reception, which the Club’s management will not be responsible for whatsoever.
                            </div>
                            <br />
                            <div><h5>12.	MONTY CLUB & MEMBERS’ PROPERTY</h5></div>
                            <br />
                            <div>
                            12.1.	 Club Members and their Guest(s) shall not remove or damage any contents or fabric of either the Club or any article belonging to the Club, Club Members, Guests, staff members, and all other third parties, whether by accident, negligence or otherwise. Otherwise, any such breach may result in immediate termination of the Club Membership of the Member or, if the latter, the Member responsible for the Guest breaching this term, notwithstanding any other right and/or remedy available at law or in equity to Monty Club.
                            </div>
                            <br />
                            <div>
                            12.2.	If a Club Member or Guest of a Member causes damage or destruction to any such property, then such Member shall be fully responsible for indemnifying all loss suffered by the Club as a result of such damage or destruction.
                            </div>
                            <br />
                            <div>
                            12.3.	Club Member’s and Guest’s property always remains their personal responsibility during their use of the Club. The Club has no liability for any loss or damage to any property or luggage left anywhere on the Club Premises, including the cloakrooms.
                            </div>
                            <br />
                            <div><h5>13.	MEMBERS’ EVENTS</h5></div>
                            <br />
                            <div>
                            13.1.	Monty Club has developed Monty Club mobile application for members to create, book events and invite guests through the App. Club Members are allowed to invite guests and shall inform the Club management either through the App or by contacting the Club’s reception.
                            </div>
                            <br />
                            <div>
                            13.2.	The Club Membership team should be contacted with regards to all queries relating to booking into Members’ events.
                            </div>
                            <br />
                            <div><h5>14.	INTERNET USAGE</h5></div>
                            <br />
                            <div>
                            14.1.	Whilst the Club provides a Wi-Fi system as part of the Services, the Club is not responsible for the upkeep of Club Members’ individual machines or to be available to answer individual IT questions.
                            </div>
                            <br />
                            <div>
                            14.2.	The Club is not responsible for any internet service failures at the Club and shall not be liable for any loss caused whatsoever as a result of any such internet failure or slowness.
                            </div>
                            <br />
                            <div>
                            14.3.	Club Members shall not seek to view, download, upload or circulate pornographic, offensive, or otherwise obscene material on the Club Premises. Club Members should be aware that accessing such material can be a criminal offence in some circumstances. Such information includes, but is not limited to:
                            <ul>
                                <li>Discriminatory and/or offensive material (including jokes);</li>
                                <li>Pornographic/indecent/obscene material;</li>
                                <li>Material relating to drugs and other criminal activities;</li>
                                <li>Material which the recipient might reasonably be expected to find offensive or distasteful (this includes statements which deride or denigrate a person).</li>
                            </ul>

                            </div>
                            <br />
                            <div><h5>15.	PRIVACY POLICY</h5></div>
                            <br />
                            <div>
                            15.1.	Any information that is dealt with or held by the Club is subject to its Privacy Policy which governs its collection and use of Club Members information.
                            </div>
                            <br /><div>
                            15.2.	To ensure safety and for the prevention and detection of crime, CCTV is in operation within and around the Club.
                            </div>
                            <br />
                            <div><h5>16.	DRESS CODE</h5></div>
                            <br />
                            <div>
                            16.1.	Club Members and guests must always be appropriately attired.
                            </div>
                            <br />
                            <div>
                            16.2.	The dress code for all areas of the Club premises is smart casual and members must always present a clean and tidy manner.
                            </div>
                            <br />
                            <div>
                            16.3.	Smart jeans are permitted, as are smart trainers, but only when worn with sufficiently smart attire.
                            </div>
                            <br />
                            <div>
                            16.4.	Suitcases, large bags, and gym bags must be deposited in the cloakroom and are not permitted in the Club unless a private room has been booked in advance. Over coats, anoraks, cloaks, and general outerwear must be deposited in the cloakroom and will not be permitted in the Club. Umbrellas must be left at the Club reception.
                            </div>
                            <br />
                            <div>
                            16.5.	The Club Membership Manager’s decision is absolute and final in all matters relating to dress.
                            </div>
                            <br />
                            <div><h5>17.	HEALTH & SAFETY</h5></div>
                            <br />
                            <div>
                            17.1.	 The Club shall not in any way be responsible or liable for any accident or injury that may occur on the Club Premises and for any loss or damages arising there from. 
                            </div>
                            <br />

                            <div>
                            17.2.	Club Members shall and shall procure that their Guests take reasonable care when using the Club Services and travelling throughout the Club Premises and must report any health and safety concerns to the Club staff immediately.
                            </div>
                            <br />

                            <div><h5>18.	GOVERNING LAW AND JURISDICTION</h5></div>
                            <br />
                            <div>
                            18.1.	The existence, validity, construction, operation, and effect of these terms and conditions shall be determined in accordance with the laws of Lebanon. Parties agree that any action or proceeding arising out of these Terms and Conditions shall be brought and maintained in Lebanon, and hereby consent to the jurisdiction of the courts of Beirut.
                            </div>
                            <br />
                            <br />
                            <br />



                           
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        )
    }
}

export default TermsConditions;