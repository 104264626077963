import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './Routing/Routes'

class App extends Component {
  render() {
    return (
      <Router>
        <Routes />
      </Router>
    );
  }
}
export default App;