import React, { Component } from 'react';
import {
    MDBCol,
    MDBContainer, MDBRow
} from 'mdbreact';
import library from "../Assets/library.png";

class DiscoverRule extends Component {

    constructor() {
        super();
        this.state = {

        }
    }

    render() {
        return (
            <div className="discover-rules">
                <MDBRow md="12">
                    <MDBCol md="5" className='d-none d-md-block'>
                        <img src={library} alt="library" className="library"></img>
                    </MDBCol>
                    <MDBCol md="5">
                        <MDBContainer className='pt-5'>
                            <MDBRow>
                                <MDBCol md="12">
                                    <MDBRow className='my-2'>
                                        <small className='brown-color-used'>DISCOVER THE RULES</small>
                                    </MDBRow>
                                    <MDBRow className='my-2'>
                                        <h2 className='rule-title-size'>
                                            Rules & By-laws
                                        </h2>
                                    </MDBRow>
                                    <MDBRow className='my-3 mt-5'>
                                        <p className='middle'>The mutual enjoyment of the club by all of its Members is central to The Club’s existence. For guidance, The Club’s code of conduct is found in the Rules & By-laws which all Members and their guests are expected to follow.</p>
                                    </MDBRow>
                                    <MDBRow className='my-2'>
                                        <ul className='px-0 my-2'>
                                            <li className='my-3'>
                                                Members are asked to please refrain from taking pictures anywhere in the club to respect other Members’ privacy
                                            </li>
                                            <li className='my-3'>
                                                The Club is both a place of entertainment and business. Please always be considerate of Members indulging in either pursuit.
                                            </li>
                                            <li className='my-3'>
                                                We would like to remind members that smoking is only permitted in the designated smoking areas in the club
                                            </li>
                                            <li className='my-3'>
                                                Members are encouraged to dress for the occasion and be respectful in their choice of attire
                                            </li>
                                        </ul>
                                    </MDBRow>
                                    {/* <MDBRow className='my-2 mt-5'>
                                        <button className='more-button'>
                                            MORE
                                        </button>
                                    </MDBRow> */}
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }

}

export default DiscoverRule