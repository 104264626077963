import React, { Component } from 'react';
import {
    MDBCol,
    MDBContainer, MDBRow
} from 'mdbreact';

import logo1 from '../Assets/logo.png';
import Link from 'react-scroll/modules/components/Link';
import TermsConditions from './TermsConditions';
import FAQ from './FAQ';

class Footer extends Component {

    constructor() {
        super();
        this.state = {
            openTermsConditions: false,
            openFAQ: false
        }
    }

    toggleTermsConditions = () => {
        this.setState({
            openTermsConditions: !this.state.openTermsConditions
        })
    }

    toggleFAQ = () => {
        this.setState({
            openFAQ: !this.state.openFAQ
        })
    }

    backToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
          spy: "true"
        });
    }

    render() {
        return (
            <>
                <MDBContainer className='d-none d-md-block footer-content'>
                    <MDBRow>
                        <MDBCol md="2">
                            <MDBRow>
                                <img onClick={this.backToTop} src={logo1} alt="Monty Logo" className='mr-3 footer-logo-sizing'/>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol align="right" className='ul-list' md="10">
                            <ul className="d-inline">
                                <li className="d-inline pr-5"><Link activeClass="active" className="text-uppercase" to="aboutus" spy={true} smooth={true} duration={500} >   ABOUT US   </Link></li>
                                <li className="d-inline pr-5"><Link activeClass="active" className="text-uppercase" to="media" spy={true} smooth={true} duration={500} > MEDIA  </Link></li>
                                <li className="d-inline pr-5"><Link activeClass="active" className="text-uppercase" to="members" spy={true} smooth={true} duration={500} > MEMBERS  </Link></li>
                                <li className="d-inline"><Link activeClass="active" className="text-uppercase" to="contactus" spy={true} smooth={true} duration={500} > CONTACT US  </Link></li>
                            </ul>
                        </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                        <MDBCol md="6">
                            <p className='copyright'>Copyright 2022 All Rights Reserved</p>
                        </MDBCol>
                        <MDBCol align="right" md="6" className='under-footer'>
                            <ul className="d-inline">
                                <li className="d-inline pr-5" onClick={this.toggleFAQ}>   FAQ   </li>
                                <li className="d-inline" onClick={this.toggleTermsConditions}> Terms of Use  </li>
                            </ul>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer className='d-block d-md-none footer-content'>
                    <MDBRow>
                        <MDBCol size="6" className='d-flex justify-content-start'>
                            <MDBRow>
                                <img onClick={this.backToTop} src={logo1} alt="Monty Logo" className=' footer-logo-sizing'/>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size="6" className='d-flex justify-content-end'>
                            <ul className="footer-menu-list">
                                <li><Link activeClass="active" className="text-uppercase" to="aboutus" spy={true} smooth={true} duration={500} >   ABOUT US   </Link></li>
                                <li><Link activeClass="active" className="text-uppercase" to="media" spy={true} smooth={true} duration={500} > MEDIA  </Link></li>
                                <li><Link activeClass="active" className="text-uppercase" to="members" spy={true} smooth={true} duration={500} > MEMBERS  </Link></li>
                                <li><Link activeClass="active" className="text-uppercase" to="contactus" spy={true} smooth={true} duration={500} > CONTACT US  </Link></li>
                            </ul>
                        </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                        <MDBCol className='d-flex justify-content-between under-footer mb-3' md="12">
                            <li className="d-inline pr-5" onClick={this.toggleFAQ}>   FAQ   </li>
                            <li className="d-inline" onClick={this.toggleTermsConditions}> Terms of Use  </li>
                        </MDBCol>
                        <MDBCol size="12" className='text-center'>
                            <p className='copyright'>Copyright 2022 All Rights Reserved</p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <TermsConditions openTermsConditions={this.state.openTermsConditions} toggleTermsConditions={this.toggleTermsConditions}/>
                <FAQ openFAQ={this.state.openFAQ} toggleFAQ={this.toggleFAQ}/>
            </>
        )
    }
}

export default Footer