import React, { Component } from 'react';
import {
    MDBCol,
    MDBContainer, MDBRow,
    MDBCarousel, MDBCarouselInner, MDBCarouselItem,
    MDBGallery,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
} from 'mdbreact';
import One from "../Assets/capturedmoments/1.jpg";
import Two from "../Assets/capturedmoments/2.jpg";
import Three from "../Assets/capturedmoments/3.jpg";
import Four from "../Assets/capturedmoments/4.jpg";
import Five from "../Assets/capturedmoments/5.jpg";
import Six from "../Assets/capturedmoments/6.jpg";
import Seven from "../Assets/capturedmoments/7.jpg";
import Eight from "../Assets/capturedmoments/8.jpg";
import Nine from "../Assets/capturedmoments/9.jpg";
import Ten from "../Assets/capturedmoments/10.jpg";
import Eleven from "../Assets/capturedmoments/11.jpg";
import Twelve from "../Assets/capturedmoments/12.jpg";
import Thirteen from "../Assets/capturedmoments/13.jpg";
import Fourteen from "../Assets/capturedmoments/14.jpg";
import Fifteen from "../Assets/capturedmoments/15.jpg";
import Sixteen from "../Assets/capturedmoments/16.jpg";
import Seventeen from "../Assets/capturedmoments/17.jpg";
import Eightteen from "../Assets/capturedmoments/18.jpg";
//large
import OneLarge from "../Assets/capturedmoments/1-large.jpg";
import TwoLarge from "../Assets/capturedmoments/2-large.jpg";
import ThreeLarge from "../Assets/capturedmoments/3-large.jpg";
import FourLarge from "../Assets/capturedmoments/4-large.jpg";
import FiveLarge from "../Assets/capturedmoments/5-large.jpg";
import SixLarge from "../Assets/capturedmoments/6-large.jpg";
import SevenLarge from "../Assets/capturedmoments/7-large.jpg";
import EightLarge from "../Assets/capturedmoments/8-large.jpg";
import NineLarge from "../Assets/capturedmoments/9-large.jpg";
import TenLarge from "../Assets/capturedmoments/10-large.jpg";
import ElevenLarge from "../Assets/capturedmoments/11-large.jpg";
import TwelveLarge from "../Assets/capturedmoments/12-large.jpg";
import ThirteenLarge from "../Assets/capturedmoments/13-large.jpg";
import FourteenLarge from "../Assets/capturedmoments/14-large.jpg";
import FifteenLarge from "../Assets/capturedmoments/15-large.jpg";
import SixteenLarge from "../Assets/capturedmoments/16-large.jpg";
import SeventeenLarge from "../Assets/capturedmoments/17-large.jpg";
import EightteenLarge from "../Assets/capturedmoments/18-large.jpg";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

import "./OurGallery.scss";


class OurGallery extends Component {

    constructor() {
        super();
        this.state = {
           modalOpen:false,
           selectedActiveItem:1,
        }
    }

    openModalToggle = (selected)=>{
        this.setState({modalOpen:!this.state.modalOpen,selectedActiveItem:selected})
    }

    render() {
        const {modalOpen,selectedActiveItem} =this.state;
        return (
            <div className='our-gallery'>
                <MDBContainer className='desktop-padding'>
                    <MDBCol>
                        <MDBRow className='pt-5 pb-3'>
                            <MDBCol size="12" className='d-flex justify-content-center'>
                                <small className='brown-color-used more-letter-spacing'>OUR GALLERY</small>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow center className='pb-5'>
                            <h2 className=' text-center'>
                                Captured Moments <br/> From Special Events
                            </h2>
                        </MDBRow>
                        {/* <MDBRow center className='py-4 text-center'>
                            <span className='w-50'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                            </span>
                        </MDBRow> */}
                        <MDBContainer>
                            <MDBCarousel
                                activeItem={1}
                                length={3}
                                showControls={true}
                                showIndicators={true}
                                className="z-depth-1"
                            >
                                <MDBCarouselInner>
                                    <MDBCarouselItem itemId="1">
                                        <MDBGallery cols={3}>
                                            <div class="row" style={{ width: '100% !important', height: 'none !important' }}>
                                                <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                                                    <img
                                                        src={One}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Boat on Calm Water"
                                                        onClick={()=>{this.openModalToggle(1)}}
                                                    />
                                                    <img
                                                        src={Two}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Wintry Mountain Landscape"
                                                        onClick={()=>{this.openModalToggle(2)}}
                                                    />
                                                </div>
                                                <div class="col-lg-4 mb-4 mb-lg-0">
                                                    <img
                                                        src={Three}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Mountains in the Clouds"
                                                        onClick={()=>{this.openModalToggle(3)}}
                                                    />
                                                    <img
                                                        src= {Four}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Boat on Calm Water"
                                                        onClick={()=>{this.openModalToggle(4)}}
                                                    />
                                                </div>
                                                <div class="col-lg-4 mb-4 mb-lg-0">
                                                    <img
                                                        src={Five}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Waves at Sea"
                                                        onClick={()=>{this.openModalToggle(5)}}
                                                    />
                                                    <img
                                                        src={Six}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Yosemite National Park"
                                                        onClick={()=>{this.openModalToggle(6)}}
                                                    />
                                                </div>
                                            </div>
                                        </MDBGallery>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="2">
                                        <MDBGallery cols={3}>
                                            <div class="row" style={{ width: '100% !important', height: 'none !important' }}>
                                                <div class="col-lg-4 mb-4 mb-lg-0">
                                                    <img
                                                        src={Seven}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Waves at Sea"
                                                        onClick={()=>{this.openModalToggle(7)}}
                                                    />

                                                    <img
                                                        src={Eight}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Yosemite National Park"
                                                        onClick={()=>{this.openModalToggle(8)}}
                                                    />
                                                </div>
                                                <div class="col-lg-4 mb-4 mb-lg-0">
                                                    <img
                                                        src={Nine}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Mountains in the Clouds"
                                                        onClick={()=>{this.openModalToggle(9)}}
                                                    />

                                                    <img
                                                        src={Ten}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Boat on Calm Water"
                                                        onClick={()=>{this.openModalToggle(10)}}
                                                    />
                                                </div>
                                                <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                                                    <img
                                                        src={Eleven}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Boat on Calm Water"
                                                        onClick={()=>{this.openModalToggle(11)}}
                                                    />
                                                    <img
                                                        src={Twelve}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Wintry Mountain Landscape"
                                                        onClick={()=>{this.openModalToggle(12)}}
                                                    />
                                                </div>
                                            </div>
                                        </MDBGallery>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="3">
                                        <MDBGallery cols={3}>
                                            <div class="row" style={{ width: '100% !important', height: 'none !important' }}>
                                                <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                                                    <img
                                                        src={Thirteen}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Boat on Calm Water"
                                                        onClick={()=>{this.openModalToggle(13)}}
                                                    />
                                                    <img
                                                        src={Fourteen}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Mountains in the Clouds"
                                                        onClick={()=>{this.openModalToggle(14)}}
                                                    />
                                                </div>
                                                <div class="col-lg-4 mb-4 mb-lg-0">
                                                    <img
                                                        src={Fifteen}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Wintry Mountain Landscape"
                                                        onClick={()=>{this.openModalToggle(15)}}
                                                    />
                                                    <img
                                                        src={Sixteen}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Waves at Sea"
                                                        onClick={()=>{this.openModalToggle(16)}}
                                                    />
                                                </div>
                                                <div class="col-lg-4 mb-4 mb-lg-0">     
                                                    <img
                                                        src={Seventeen}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Boat on Calm Water"
                                                        onClick={()=>{this.openModalToggle(17)}}
                                                    />
                                                     <img
                                                        src={Eightteen}
                                                        class="w-100 shadow-1-strong rounded mb-4"
                                                        alt="Yosemite National Park"
                                                        onClick={()=>{this.openModalToggle(18)}}
                                                    />
                                                </div>
                                            </div>
                                        </MDBGallery>
                                    </MDBCarouselItem>
                                </MDBCarouselInner>
                            </MDBCarousel>
                        </MDBContainer>

                    </MDBCol>
                </MDBContainer>

                <MDBModal toggle={()=>{this.openModalToggle()}} isOpen={modalOpen} centered size='xl' className='transparent-model'>
                    <MDBModalHeader toggle={()=>{this.openModalToggle()}}>
                      
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBCarousel
                         activeItem={selectedActiveItem}
                         length={18}
                         showControls={true}
                         showIndicators={true}
                        //  className="z-depth-1"
                        >
                            <MDBCarouselInner >
                                <MDBCarouselItem itemId={1}>
                                <div className='w-100 half-sized-image'>
                                    <img
                                        src={OneLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Boat on Calm Water"
                                    />
                                </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={2} className={"d-flex justify-content-center"}>
                                    <div className='w-100 half-sized-image'>
                                        <img
                                            src={TwoLarge}
                                            class="w-100 shadow-1-strong rounded mb-4"
                                            alt="Wintry Mountain Landscape"
                                        />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={3}>
                                <div className='w-100 half-sized-image'>
                                    <img
                                        src={ThreeLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Mountains in the Clouds"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={4}>
                                <div className='w-100 half-sized-image'>
                                    <img
                                        src= {FourLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Boat on Calm Water"
                                    />
                                </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={5}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={FiveLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Waves at Sea"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={6}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={SixLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Yosemite National Park"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={7}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={SevenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Waves at Sea"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={8}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={EightLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Mountains in the Clouds"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={9}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={NineLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Boat on Calm Water"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={10}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={TenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Boat on Calm Water"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={11}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={ElevenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Wintry Mountain Landscape"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={12}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={TwelveLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Wintry Mountain Landscape"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={13}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={ThirteenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Wintry Mountain Landscape"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={14}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={FourteenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Wintry Mountain Landscape"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={15}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={FifteenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Wintry Mountain Landscape"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={16}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={SixteenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Wintry Mountain Landscape"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={17}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={SeventeenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Wintry Mountain Landscape"
                                    />
                                    </div>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={18}>
                                    <div className='w-100 half-sized-image'>
                                    <img
                                        src={EightteenLarge}
                                        class="w-100 shadow-1-strong rounded mb-4"
                                        alt="Wintry Mountain Landscape"
                                    />
                                    </div>
                                </MDBCarouselItem>
                            </MDBCarouselInner>
                        </MDBCarousel>

                    </MDBModalBody>
                </MDBModal>

            </div>
        )
    }

}

export default OurGallery