import { MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow } from 'mdbreact';
import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';

class FAQ extends Component {
    constructor() {
        super();
        this.state = {}
    }

    render() {
        return (
            <MDBModal
                size="xl"
                centered
                isOpen={this.props.openFAQ}
                toggle={this.props.toggleFAQ}
                className="h-auto custom-blurry-modal faq modal-fluid"
            >
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="12" className='d-flex justify-content-end'>
                            <button className='x-times' onClick={this.props.toggleFAQ}>
                                <MDBIcon icon="times"/>
                            </button>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol xl="3" lg="3">
                            <MDBRow>
                                <small className='brown-color-used margin-on-bigger-screen'>DISCOVER our service</small>
                            </MDBRow>
                            <MDBRow>
                                <h2 className='pt-4 margin-on-bigger-screen'>
                                    FAQ
                                </h2>
                            </MDBRow>
                        
                        </MDBCol>
                        <MDBCol xl="9" lg="9" className='scrollable pb-5'>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is Monty Club app?</Accordion.Header>
                                    <Accordion.Body>
                                        Monty Club’s application (iOS and Android) aims at digitalizing attendance to Monty Club. 
                                        <br/>Members of the Club can create private events from within the app and invite guests to their events. 
                                        <br/><br/>To access an event at the Club, guests will have to scan the QR code received via their application, using the QR code scanner found in the elevator. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>From where can I download the Monty Club app?</Accordion.Header>
                                    <Accordion.Body>
                                        You can download Monty Club application, for free, from Google play if you have an Android device and from Apple store if you have an iOS device.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>As a guest, do I need to register to Monty Club app to receive the QR code for an event?</Accordion.Header>
                                    <Accordion.Body>
                                        As an invited guest, you will have to click on “I’m a Guest”, and enter your name and number. You will receive an OTP (One Time Password) via SMS to verify your account. Once verified, the account is created and ready to go.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>I didn’t receive an OTP, what shall I do?</Accordion.Header>
                                    <Accordion.Body>
                                        If you didn’t receive the OTP, you can click on “Resend Code”. 
                                    	<br/>If the issue persists, kindly contact us on +961 81 344 244 or info@montyclub.co.uk.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Can I turn off the notifications?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, you can turn off notifications from the Settings section.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Where can I view the QR code of a specific event?</Accordion.Header>
                                    <Accordion.Body>
                                        You can view all your confirmed events under the Events tab. Click on a specific event to view all its relative details, including its QR code.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>I declined the event by mistake, can I re-confirm my attendance?</Accordion.Header>
                                    <Accordion.Body>
                                        Unfortunately, you cannot. You have to be re-invited. Please contact the event management on +961 81 344 244 or  info@montyclub.co.uk
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>
                                        Where can I see all the Events I am invited to?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        In the Events tab, you can see the Confirmed, Pending, Declined and Cancelled events.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>
                                        What are Confirmed events?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The confirmed events are the events that you have previously accepted to attend.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>
                                        What are Pending events?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The pending events are the events that you are invited to and that you still did not confirm or decline.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>
                                        What are Declined events?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The declined events are the events that you have rejected to attend.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>
                                        What are Cancelled events?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The cancelled events are the events that were cancelled by the event management team. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header>
                                        Will I be notified in case the event date has been changed or cancelled?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Yes, you will receive a notification in case the date or time of the event has been changed, or the event has been cancelled.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header>
                                        Where is the QR code used? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The QR code shall be scanned in the elevator, parallel and facing the camera (QR code scanner).
                                        <br/>This will give you access to the 6th floor where Monty Club is located.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header>
                                        Why was my QR code working yesterday, but not today?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The Event QR code is valid for the event only and expires as the event ends.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header>
                                        What is the VIP Access code?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The VIP Access Code is a permanent QR Code granted by the management to specific individuals. The VIP Access Code does not expire. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                    <Accordion.Header>
                                        What is the difference between Event QR code and VIP Access code?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        What is the difference between Event QR code and VIP Access code?
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17">
                                    <Accordion.Header>
                                        Can I download the QR code as PDF or Photo?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        No, the QR code can be scanned only through the application.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="18">
                                    <Accordion.Header>
                                        Can two people use the same QR code?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Each QR code is specifically sent to a unique guest and can’t be used by others.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="19">
                                    <Accordion.Header>
                                        I’m facing issues with my QR code, who can I contact?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        For help, please contact on +961 81 344 244 or info@montyclub.co.uk
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="20">
                                    <Accordion.Header>
                                        How can I become a member of Monty Club?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Click on “Membership Area” on Monty Club app and then on “Register now”. Fill in the membership form. The Club management will review your request and get in contact with you within 7 working days. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="21">
                                    <Accordion.Header>
                                        I tried signing in using my mobile number but didn’t receive an OTP/Verification code, what shall I do?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        If OTP is not received, click on “Resend Code”. If you are still facing issues regarding the OTP, please contact us on +961 81 344 244 or info@montyclub.co.uk.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="22">
                                    <Accordion.Header>
                                        How can I create a private event?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Sign in to the app as a member and click on + sign within the Events section to add an event. Fill in all the needed details and click on “Send request”. A brief email with your event details will be sent to the Club’s management who will handle your event’s approval/rejection.
                                        <br/><br/>Your event will remain under the ”Pending” tab until management approves/rejects. Once your event is approved, it will be listed under “Upcoming”, and you can start inviting your friends to it. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="23">
                                    <Accordion.Header>
                                        How can I invite my friends to my event?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        After you create an event, your event will remain under ”Pending” tab until management approves/rejects the event. Once your event is approved, it will be listed under “Upcoming”, and you can start inviting your friends to it. Click on “View Event” then on “Invite”. Select the friends you want to invite and then click on “Invite” on the upper right of the screen. Enter your personalized message and then click on “Send” then “Invite”.  If you do not enter a personalized message, the default message will be sent instead. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="24">
                                    <Accordion.Header>
                                        How will I know my requested event was approved/rejected?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You will receive an in-app notification updating you on the status of your event. 
                                        <br/><br/>Moreover, your event will remain under ”Pending” tab until the Club management approves or rejects the event. Once your event is approved, it will be listed under “Upcoming”. If it was rejected, it will be listed under “Rejected. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="25">
                                    <Accordion.Header>
                                        Where can I view the invitees’ names to a certain event?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        In Events section, you can check all details related to your event under the Event’s name, including the invitees. If you click on “Invitees”, you can see their names.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="26">
                                    <Accordion.Header>
                                        Can I cancel my event and how?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can only cancel an event if it is “Pending”. Click on the “…” to the right of “View Event”, and then on “Cancel Event”. Enter a customized notification to inform all invitees about the event’s cancellation. If you do not enter a customized message, the default message will be sent instead. 
                                        <br/><br/>You cannot cancel an event if it is already approved. You shall contact the Club management on +961 81 344 244 or info@montyclub.co.uk 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="27">
                                    <Accordion.Header>
                                        How many friends can I invite to my event?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        When you create your event, you shall specify the number of guests. Once your event is approved by management, the number of guests shall not exceed this limit. 
                                        <br/><br/>In the case an invited guest declined your invitation, you can invite another guest instead. 
                                        For any changes regarding the number of guests, please contact the Club management on:
                                        +961 81 344 244 or info@montyclub.co.uk. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="28">
                                    <Accordion.Header>
                                        Can I turn off the notifications?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Yes, you can turn off notifications from the Settings section.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        )
    }
}

export default FAQ;