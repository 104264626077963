import React, { Component } from 'react';
import {
    MDBCol,
    MDBContainer, MDBRow, MDBInput, MDBIcon
} from 'mdbreact';

import facebook from '../Assets/social/facebook.svg';
import instagram from '../Assets/social/instagram.svg';
import { API_CONTACTUS } from "../API/index"
import Axios from "axios";
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Joi from 'joi';
import phoneIcon from "../Assets/phone-icon.svg";
import sendMessage from "../Assets/sendMessage.png";

class ContactUs extends Component {

    constructor() {
        super();
        this.state = {
            fullName: "",
            mobileNumber: "",
            email: "",
            companyName: "",
            message: "",
            loading: false,
            error:"",
        }
    }

    onChangeField = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    sendMessage() {
        const validationError = this.validateInput();
        if (validationError?.error){
            this.setState({...this.state,error:validationError?.error?.details[0]?.message})
            // toast.error(validationError?.error?.details[0]?.message)
        } else {
            this.setState({
                loading: true
            })
            const {
                fullName,
                email,
                mobileNumber,
                companyName,
                message
            } = this.state;
            const postData =
            {
                "fullName": fullName,
                "email": email,
                "mobileNumber": mobileNumber,
                "companyName": companyName,
                "message": message
            };
            Axios.post(API_CONTACTUS,
                        postData,
                        { headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                        "Content-Type": "application/json",
                        "Accept-Language": "en-US"
                        }}
                        )
            .then(res => {
                this.setState({
                    fullName: "",
                    mobileNumber: "",
                    email: "",
                    companyName: "",
                    message: "",
                    loading: false
                })
                toast.success("Sent Successfully")
            })
            .catch(err => {
                console.log(err.message);
                // toast.error(err?.message ?? "An Error Occured");
                this.setState({...this.state,error:err.message})
            }).finally(() => {
                this.setState({
                    fullName: "",
                    mobileNumber: "",
                    email: "",
                    companyName: "",
                    message: "",
                    loading: false
                })
            })
        }
    }

    validateInput = ()=>{
        this.setState({...this.state,error:""})
        const schema = Joi.object({
            FullName: Joi.string().required(),
            MobileNumber: Joi.string().min(6).pattern(/^[0-9]+$/).messages({"string.pattern.base":`Mobile Number must be a number`}),
            Email: Joi.string().email({ tlds: { allow: false } }).allow(''),
            CompanyName:Joi.string().allow(''),
            Message:Joi.string().required(),
        })

        const error = schema.validate({
            FullName: this.state.fullName,
            MobileNumber:this.state.mobileNumber,
            Email:this.state.email,
            CompanyName:this.state.companyName,
            Message:this.state.message
        })

        return error;
    }

    render() {
        const {
            fullName,
            email,
            mobileNumber,
            companyName,
            message
        } = this.state;

        return (
            <>
                <MDBContainer className='desktop-padding'>
                    <ToastContainer/>
                    <MDBRow>
                        <MDBCol md='6'>
                            <MDBRow className='pt-5 pb-3'>
                                <small className='brown-color-used font-weight-bold more-letter-spacing'>GET IN TOUCH</small>
                            </MDBRow>
                            <MDBRow>
                                <h2 className='bigger-title-size'>
                                    Contact Us
                                </h2>
                            </MDBRow>
                            <MDBRow className="my-3">
                                <p>
                                    For further information, please get in touch
                                </p>
                            </MDBRow>
                            <MDBRow className="mt-3">
                                <p><MDBIcon far icon="envelope" className='brown-color-used mr-2' /> info@montyclub.co.uk</p>
                            </MDBRow>
                            <MDBRow className="mb-5">
                                <p><img src={phoneIcon} alt="phone" width={"15px"} height={"15px"} className='brown-color-used mr-2' /> Phone: 81-344244</p>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" className='d-flex'>
                                    <img src={facebook} alt="facebook" className='mr-3 hover social-buttons'  onClick={()=>{window.open("https://www.facebook.com/MontyPrivateClub/");}}/>
                                    {/* <img src={tiktok} alt="tiktok" className='mr-3 hover' width="21px" height="21px"/>
                                    <img src={linkedin} alt="linkedin" className='mr-3 hover' width="21px" height="21px"/> */}
                                    <img src={instagram} alt="instagram" className='mr-3 hover social-buttons'  onClick={()=>{window.open("https://www.instagram.com/monty_privateclub/");}}/>
                                    {/* <img src={twitter} alt="twitter" className='mr-3 hover' width="21px" height="21px"/> */}
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol md='6' className='py-5'>
                            <MDBRow>
                                <MDBCol md="6" className='inputs'>
                                    <div className="md-form mb-0">
                                        <MDBInput 
                                            type="text" 
                                            id="contact-name" 
                                            label="Full Name"
                                            name="fullName"
                                            value={fullName}
                                            onChange={this.onChangeField}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol md="6" className='inputs'>
                                    <div className="md-form mb-0">
                                        <MDBInput
                                            type="text"
                                            id="contact-number"
                                            label="Mobile Number"
                                            name="mobileNumber"
                                            value={mobileNumber}
                                            onChange={this.onChangeField}
                                        />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6" className='inputs'>
                                    <div className="md-form mb-0">
                                        <MDBInput 
                                            type="text" 
                                            id="contact-email" 
                                            label="Email"
                                            name="email"
                                            value={email}
                                            onChange={this.onChangeField}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol md="6" className='inputs'>
                                    <div className="md-form mb-0">
                                        <MDBInput
                                            type="text"
                                            id="contact-company-name"
                                            label="Company Name"
                                            name="companyName"
                                            value={companyName}
                                            onChange={this.onChangeField}
                                        />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="12" className='inputs'>
                                    <div className="md-form mb-0">
                                        <textarea 
                                            className="message" 
                                            placeholder='Message' 
                                            rows='4' 
                                            type="text" 
                                            label="Message"
                                            name="message"
                                            value={message}
                                            onChange={this.onChangeField}
                                        />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <p className='text-danger'>
                                {this.state.error}
                            </p>
                            <div className="text-left middle-contact-btn">
                                {/* <button className='more-button' onClick={() => {this.sendMessage()}} disabled={this.state.loading}>
                                    {this.state.loading ? "SENDING..." : "SEND MESSAGE"}
                                </button> */}
                                <img src={sendMessage} alt={"Send Message"} width={"200px"} height={"50px"} onClick={() => {if(!this.state.loading){this.sendMessage()}}} className="hover"/>
                                {this.state.loading && <MDBIcon icon="circle-notch" spin size="2x" fixed  className='mt-2 mx-2'/>}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </>
        )
    }
}

export default ContactUs