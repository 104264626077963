import React from 'react';
import {
  MDBNavbar, MDBNavbarNav, MDBNavbarToggler,
  MDBCollapse, MDBNavItem, MDBContainer
} from 'mdbreact';
import { Link, Events, animateScroll as scroll, scroller } from 'react-scroll'

import logo1 from '../Assets/logo1.webp';

class TopNav extends React.Component {
  constructor() {
    super();
    this.state = {
      collapse: false,
      isWideEnough: false,
      isMobile: window.innerWidth > 960 ? false : true
    };
    this.scrollToTop = this.scrollToTop.bind(this);
    this.openCollapse = this.openCollapse.bind(this);
  }

  componentDidMount() {
    this.isMobile = window.innerWidth > 960 ? false : true
    console.log("window.innerWidth: ", window.innerWidth)

    Events.scrollEvent.register('begin', function () {
      console.log("begin", arguments);
    });
    Events.scrollEvent.register('end', function () {
      console.log("end", arguments);
    });
  }

  openCollapse() {
    this.setState({
      collapse: !this.state.collapse
    })
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });
      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      })
    );
  }

  render() {
    const { isMobile } = this.state;

    return (
      <>
        <MDBNavbar className="top-nav-wide text-center" color="bg-primary" fixed="top" dark expand="lg" scrolling transparent>
          <MDBContainer>
            <div className="d-none d-lg-block">
              {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.openCollapse} />}
            </div>
            <div className={this.state.collapse ? "darker-collapser-div" : "collapser-div"}>
              <img src={logo1} alt="home" width="80px" onClick={this.scrollToTop} />
              <MDBNavbarToggler onClick={this.openCollapse} />
            </div>
            <MDBCollapse isOpen={this.state.collapse && isMobile} navbar>
              <MDBNavbarNav center>
                <MDBNavItem>
                  <li className={this.state.collapse && isMobile ? "" : "aboutus"}>
                    <Link onClick={this.openCollapse} activeClass="active" className="text-uppercase" to="aboutus" spy={true} smooth={true} duration={500} >About Us</Link>
                  </li>
                </MDBNavItem>
                <MDBNavItem>
                  <li className={this.state.collapse && isMobile ? "" : "members"}>
                    <Link onClick={this.openCollapse} className=" text-uppercase" to="members" spy={true} smooth={true} duration={500}>Members</Link>
                  </li>
                </MDBNavItem>
                <MDBNavItem className="d-none d-lg-block">
                  <li><Link onClick={this.openCollapse} className="logo home" to="home" spy={true} smooth={true} duration={500}>
                    <img src={logo1} alt="home" onClick={this.scrollToTop} />
                  </Link></li>
                </MDBNavItem>
                <MDBNavItem>
                  <li className={this.state.collapse && isMobile ? "" : "media"}>
                    <Link onClick={this.openCollapse} className=" text-uppercase" to="media" spy={true} smooth={true} duration={500}>Media</Link>
                  </li>
                </MDBNavItem>
                <MDBNavItem>
                  <li className={this.state.collapse && isMobile ? "" : "contactUs"}>
                    <Link onClick={this.openCollapse} className=" text-uppercase" to="contactus" spy={true} smooth={true} duration={500}>Contact Us</Link>
                  </li>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </>
    );
  }
}

export default TopNav