import React, { Component } from 'react';
import lib from '../Assets/libraryImage.png'
import bar from '../Assets/bar.png'
import cigar from '../Assets/cigar.png'
import privateRoom from '../Assets/privateRoom.png'
import terrace from '../Assets/terrace.png'
import dining from '../Assets/dining.png'

import libraryIcon from '../Assets/library.svg'
import diningIcon from '../Assets/dining-area.svg'
import cigarIcon from '../Assets/cigar.svg'
import tvIcon from '../Assets/tv.svg'
import outdoorIcon from '../Assets/outdoor.svg'
import barIcon from '../Assets/bar.svg'
// import privateIcon from '../Assets/private-room.svg'
import location from '../Assets/location.svg'
import timer from '../Assets/timer.svg'
import './AboutUs.scss'
import {
    MDBCol,
    MDBContainer, MDBRow
} from 'mdbreact';

class AboutUs extends Component {

    constructor() {
        super();
        this.state = {

        }
    }

    render() {
        return (
            <div className='about-us'>
                <MDBContainer>
                    <MDBRow className='about-us-description'>
                        <MDBCol md="3">
                            <small className='brown-color-used font-weight-bold more-letter-spacing'>DISCOVER THE CLUB</small>
                            <h2 className='font-weight-bold bigger-title-size pt-4'>
                                About Us
                            </h2>
                        </MDBCol>
                        <MDBCol md="5" className='description'>
                            <p>An exclusive space reserved only to Members and designed especially for their convenience. With a timeless combination of comfort, glamour and privacy, Monty Club continually strives to create an unforgettable and celebratory atmosphere.</p>
                            <p>At Monty Club the membership is made up of hand-picked like-minded people.</p>
                            <p> With its unrivalled atmosphere, incomparable service, and exquisite menu, The Club is an exceptional venue to entertain and be entertained.</p>
                        </MDBCol>
                        <MDBCol md="4">
                            <div>
                                <MDBRow>
                                    <MDBCol size="3" className='logo-container mt-2' top>
                                        <img src={timer} alt="Time" width={"35px"} height={"35px"}/>
                                    </MDBCol>
                                    <MDBCol size="9" className='hours-container'>
                                        <h4 className='font-weight-bold'>
                                            Opening Hours
                                        </h4>
                                        <p>
                                            Everyday, 10.00 am - Midnight
                                        </p>
                                    </MDBCol>
                                    <MDBCol size="3" className='logo-container location' top>
                                        <img src={location} alt="Location" width={"35px"} height={"35px"} className={"mt-1"}/>
                                    </MDBCol>
                                    <MDBCol size="9" className='location hours-container'>
                                        <h4 className='font-weight-bold'>
                                            Location
                                        </h4>
                                        <p>
                                            Gefinor Center, Block E, 6th Floor,
                                            Beirut, Lebanon 1113
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer className='gallery-container'>
                    <MDBRow className='about-img'>
                        <MDBCol xs="12" sm="12" md="6" lg="6" xl="4" className='column-container'>
                            <img src={dining} alt="dining" />
                            <div className='inner-about'>
                                <img src={diningIcon} alt="dining-icon" className='mt-3 my-3'/>
                                <h6 className='title'>Dining Area</h6>
                                <p>Perfect for a private business lunch or a cozy dinner with a warm ambiance</p>
                            </div>
                        </MDBCol>
                        <MDBCol xs="12" sm="12" md="6" lg="6" xl="4" className='column-container'>
                            <img src={lib} alt="library" />
                            <div className='inner-about'>
                                <img src={libraryIcon} alt="library-icon" className='mt-2 my-3'/>
                                <h4 className='title'>Library</h4>
                                <p>Perfect spot for a casual private meeting or an afternoon tea with friends</p>
                            </div>
                        </MDBCol>
                        <MDBCol xs="12" sm="12" md="6" lg="6" xl="4" className='column-container'>
                            <img src={cigar} alt="cigar" />
                            <div className='inner-about'>
                                <img src={cigarIcon} alt="cigar-icon" className='mt-2 my-3'/>
                                <h4 className='title'>Cigar Lounge</h4>
                                <p>A place to share your passion with like-minded members other connoisseurs</p>
                            </div>
                        </MDBCol>
                        <MDBCol xs="12" sm="12" md="6" lg="6" xl="4" className='column-container'>
                            <img src={privateRoom} alt="private-room" />
                            <div className='inner-about'>
                                <img src={tvIcon} alt="tv-icon" className='mt-2 my-3'/>
                                <h4 className='title'>TV / Private Room</h4>
                                <p>Designed to provide its members with a cozy meeting room set up</p>
                            </div>
                        </MDBCol>
                        <MDBCol xs="12" sm="12" md="6" lg="6" xl="4" className='column-container'>
                            <img src={terrace} alt="terrace" />
                            <div className='inner-about'>
                                <img src={outdoorIcon} alt="outdoor-icon" className='mt-2 my-3'/>
                                <h4 className='title'>Outdoor Terrace</h4>
                                <p>Enjoy cocktail hour on our terrace lounge. Perfect for small gatherings!</p>
                            </div>
                        </MDBCol>
                        <MDBCol xs="12" sm="12" md="6" lg="6" xl="4" className='column-container'>
                            <img src={bar} alt="bar" />
                            <div className='inner-about'>
                                <img src={barIcon} alt="bar-icon" className='mt-2 my-3'/>
                                <h4 className='title'>The bar</h4>
                                <p>Enjoy an elegant cocktail prepared by our expert mixologist</p>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }

}

export default AboutUs